import React, { useState, useEffect } from "react";
import {
  Container,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Grid,
  Typography,
  Checkbox,
  Box
} from "@mui/material";
import forcePowersData from "./data/forcePowers.json";
import forceAbilitiesData from "./data/forceAbilities.json";
import MarkedText from "./Util/MarkedText";

let forcePowers = forcePowersData
let forceAbilities = forceAbilitiesData

function TableComponent({
  tableData,
  character,
  handleCharacterChange,
  selectedForcePower,
  validPowers
}) {
  const tableRows = [];
  const getLocation = (row, column) => {
    let spans = tableData.AbilityRows[row].AbilitySpan;
    return spans
      .slice(0, column)
      .reduce((sum, num) => parseInt(sum) + parseInt(num), 0);
  };

  const getSize = (span) => {
    return (span * (12 * span - 2)) / span ** 2;
  };

  for (let row in tableData.AbilityRows) {
    tableRows.push(tableData.AbilityRows[row].Abilities);
  }
  const [boughtPowers, setBoughtPowers] = useState(character.ForcePowers);

  let checkedCells = tableData.AbilityRows.map((row) =>
    new Array(row.Abilities.length).fill(false),
  );

  if (boughtPowers[validPowers()[selectedForcePower].Key]) {
    checkedCells = boughtPowers[validPowers()[selectedForcePower].Key];
  }
  function getAlignedColumn(row, location) {
    const abilitySpan = tableData.AbilityRows[row].AbilitySpan;
    for (let i = 0; i < abilitySpan.length; i++) {
      if (
        getLocation(row, i) <= location &&
        getLocation(row, i) + parseInt(abilitySpan[i]) > location
      ) {
        return i;
      }
    }
  }

  const isConnected = (row, column) => {
    const location = getLocation(row, column);
    let spanCount = parseInt(
      tableData.AbilityRows[row].AbilitySpan[column],
    );
    while (spanCount > 0) {
      let spanIndex = spanCount - 1;
      let { Up, Down, Left, Right } =
        tableData.AbilityRows[row].Directions[location + spanIndex];
      if (
        row === 0 ||
        (Left && checkedCells[row][column - 1]) ||
        (Right && checkedCells[row][column + 1]) ||
        (Up &&
          checkedCells[row - 1][
          getAlignedColumn(row - 1, location + spanIndex)
          ]) ||
        (Down &&
          checkedCells[row + 1][
          getAlignedColumn(row + 1, location + spanIndex)
          ])
      ) {
        return true;
      }
      spanCount--;
    }
    return false;
  };

  const toggleAbility = (row, column) => {
    checkedCells[row][column] = !checkedCells[row][column];
    let updatedForcePowers = {
      ...boughtPowers,
      [validPowers()[selectedForcePower].Key]: checkedCells,
    };
    setBoughtPowers(updatedForcePowers);
    if (checkedCells[row][column]) {
      handleCharacterChange({
        XP:
          parseInt(character.XP) -
          Math.max(parseInt(tableData.AbilityRows[row].Costs[column]) -
            ((row === 0 && character.MentorBonuses[validPowers()[selectedForcePower].Key]) ? 5 : 0) -
            (character.Mentor.ForceTradition?.Benefit?.Discounts?.find((d) => d.Power === validPowers()[selectedForcePower].AbilityRows[row].Abilities[column])?.Discount || 0),
            5),
        ForcePowers: updatedForcePowers,
      });
    } else {
      handleCharacterChange({
        XP:
          parseInt(character.XP) +
          Math.max(parseInt(tableData.AbilityRows[row].Costs[column]) -
            ((row === 0 && character.MentorBonuses[validPowers()[selectedForcePower].Key]) ? 5 : 0) -
            (character.Mentor.ForceTradition?.Benefit?.Discounts?.find(d => d.Power === validPowers()[selectedForcePower].AbilityRows[row].Abilities[column])?.Discount || 0),
            5),
        ForcePowers: updatedForcePowers,
      });
    }
  };

  return (
    <TableContainer>
      <Table style={{ tableLayout: "fixed" }}>
        <TableBody>
          {tableRows.map((row, rowIndex) => (
            <TableRow key={rowIndex}>
              {row.map((cell, columnIndex) => (
                <TableCell
                  key={rowIndex + columnIndex}
                  colSpan={
                    tableData.AbilityRows[rowIndex].AbilitySpan[columnIndex]
                  }
                  sx={{
                    verticalAlign: "top",
                    padding: "0px",
                    borderBottom: "0px",
                    position: "relative",
                    overflow: "hidden",
                  }}
                >
                  <Grid container>
                    <Grid
                      item
                      xs={
                        1 /
                        tableData.AbilityRows[rowIndex].AbilitySpan[columnIndex]
                      }
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        height: "50px",
                      }}
                    >
                      <Paper
                        square
                        sx={{
                          border: "6px solid gray",
                          height: "0px",
                          minWidth: "90px",
                        }}
                        style={{
                          visibility: tableData.AbilityRows[rowIndex]
                            .Directions[
                            getLocation(rowIndex, columnIndex)
                          ].Left
                            ? "visible"
                            : "hidden",
                        }}
                      ></Paper>
                    </Grid>
                    <Grid
                      item
                      xs={getSize(
                        tableData.AbilityRows[rowIndex].AbilitySpan[columnIndex],
                      )}
                    >
                      <Paper sx={{ overflow: "visible", paddingBottom: "10px", position: 'relative' }}>
                        <Grid
                          container
                          sx={{
                            background: "#005700",
                            borderTopLeftRadius: (theme) => theme.shape.borderRadius,
                            borderTopRightRadius: (theme) => theme.shape.borderRadius,
                          }}
                        >
                          <Grid item xs={12} sm={3} md={2}>
                            <Checkbox
                              sx={{
                                padding: "3px",
                                "&.MuiCheckbox-colorPrimary": {
                                  color: "#FFFFFF",
                                },
                                "&.Mui-checked": {
                                  color: "#FFFFFF",
                                },
                                "&.Mui-disabled": {
                                  color: "#808080",
                                },
                              }}
                              checked={checkedCells[rowIndex][columnIndex]}
                              disabled={!isConnected(rowIndex, columnIndex)}
                              onChange={() =>
                                toggleAbility(rowIndex, columnIndex)
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={9} md={10}>
                            <Typography variant="h6" color="white" sx={{
                              fontSize: {
                                xs: '.7rem',
                                sm: '1rem',
                                md: '1.25rem',
                              }
                            }}>
                              {
                                forceAbilities.find(
                                  (ability) => ability.Key === cell,
                                ).Name
                              }
                            </Typography>
                          </Grid>
                        </Grid>
                        <Box
                          sx={{
                            display: 'inline-block',
                            padding: '5px',
                            fontSize: {
                              xs: '0.6rem',
                              sm: '1rem'
                            },
                          }}
                          dangerouslySetInnerHTML={{
                            __html: MarkedText.renderer(
                              forceAbilities.find(
                                (ability) => ability.Key === cell,
                              ).Description,
                            ),
                          }}
                        />
                        <Paper
                          sx={{
                            background: "#005700",
                            display: "inline-block",
                            paddingLeft: "5px",
                            paddingRight: "5px",
                            marginRight: "10px",
                            position: "absolute",
                            bottom: "-10px",
                            right: 0,
                            zIndex: 3
                          }}
                        >
                          <Typography sx={{ fontSize: { xs: '0.7rem', sm: '1rem' } }}>
                            Cost:{" "}
                            {
                              Math.max(tableData.AbilityRows[rowIndex].Costs[columnIndex] -
                                ((rowIndex === 0 && character.MentorBonuses[validPowers()[selectedForcePower].Key]) ? 5 : 0) -
                                (character.Mentor.ForceTradition?.Benefit?.Discounts?.find(d => d.Power === validPowers()[selectedForcePower].AbilityRows[rowIndex].Abilities[columnIndex])?.Discount || 0),
                                5)
                            }
                          </Typography>
                        </Paper>
                      </Paper>
                    </Grid>
                    <Grid
                      item
                      xs={
                        1 /
                        tableData.AbilityRows[rowIndex].AbilitySpan[columnIndex]
                      }
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        height: "50px",
                      }}
                    >
                      <Paper
                        square
                        sx={{
                          border: "6px solid gray",
                          height: "0px",
                          minWidth: "90px",
                        }}
                        style={{
                          visibility: tableData.AbilityRows[rowIndex]
                            .Directions[
                            getLocation(rowIndex, columnIndex)
                          ].Right
                            ? "visible"
                            : "hidden",
                        }}
                      ></Paper>
                    </Grid>
                    <Grid container>
                      {Array.from(
                        {
                          length:
                            tableData.AbilityRows[rowIndex]
                              .AbilitySpan[columnIndex],
                        },
                        (_, index) => (
                          <Grid
                            item
                            xs={
                              12 /
                              tableData.AbilityRows[rowIndex]
                                .AbilitySpan[columnIndex]
                            }
                            key={index}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "column",
                              minHeight: "30px",
                            }}
                          >
                            <Paper
                              square
                              sx={{
                                border: "6px solid gray",
                                width: "0px",
                                height: "100%",
                                position: "absolute",
                              }}
                              style={{
                                visibility: tableData.AbilityRows[
                                  rowIndex
                                ].Directions[
                                  getLocation(rowIndex, columnIndex) + index
                                ].Down
                                  ? "visible"
                                  : "hidden",
                              }}
                            ></Paper>
                          </Grid>
                        ),
                      )}
                    </Grid>
                  </Grid>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer >
  );
}

function App({ character, handleCharacterChange, appFiles }) {
  const [selectedForcePower, setSelectedForcePower] = useState("");

  const handleForcePowerChange = (event) => {
    setSelectedForcePower(event.target.value);
  };

  useEffect(() => {
    if (appFiles.forcePowers) { forcePowers = JSON.parse(appFiles.forcePowers) }
    if (appFiles.forceAbilities) { forceAbilities = JSON.parse(appFiles.forceAbilities) }
  }, [appFiles.forcePowers, appFiles.forceAbilities]);

  const validPowers = () => {
    let validP = forcePowers.filter((p) => !p.MinForceRating || p.MinForceRating <= character.ForceRating)
    return validP
  }

  const mentorToggle = () => {
    let newBonuses = { ...character.MentorBonuses }
    let key = validPowers()[selectedForcePower].Key
    let characterBonus = newBonuses[key]
    if (!characterBonus) {
      newBonuses[key] = true
    }
    else { delete newBonuses[key] }
    handleCharacterChange({ MentorBonuses: newBonuses })
  }

  return (
    <Container>
      <Typography variant="h3">Force Powers</Typography>
      <FormControl fullWidth>
        <InputLabel>Select Force Power</InputLabel>
        <Select value={selectedForcePower} onChange={handleForcePowerChange}>
          {validPowers().map((power, index) => (
            <MenuItem
              key={index}
              value={index}
              sx={(theme) => ({
                backgroundColor: character.ForcePowers[power.Key] ? 'rgba(0, 255, 0, 0.1)' : 'inherit',
                '&:hover': {
                  backgroundColor: character.ForcePowers[power.Key]
                    ? 'rgba(0, 255, 0, 0.2)'
                    : theme.palette.action.hover,
                },
                '&.Mui-selected': {
                  backgroundColor: 'rgba(0, 255, 0, 0.1)',
                  '&:hover': {
                    backgroundColor: 'rgba(0, 255, 0, 0.2)',
                  },
                },
              })}
            >
              {power.Name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {validPowers()[selectedForcePower] && (<>
        <Grid container alignItems="center" justifyContent="flex-end" sx={{ width: '100%' }}        >
          <Grid item><Checkbox
            size="small"
            checked={character.MentorBonuses[validPowers()[selectedForcePower].Key]}
            onChange={() => mentorToggle()}
          /></Grid>
          <Grid item><Typography style={{ display: 'flex', alignItems: 'center', height: '100%' }}>Mentor Bonus</Typography></Grid>
        </Grid>
        <TableComponent
          tableData={validPowers()[selectedForcePower]}
          character={character}
          handleCharacterChange={handleCharacterChange}
          selectedForcePower={selectedForcePower}
          validPowers={validPowers}
        />
      </>)}
    </Container>
  );
}

export default App;
