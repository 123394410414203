import { Paper, Typography, TextField, Grid, IconButton, Button, Select, Checkbox, MenuItem } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import skills from '../data/skills.json'
import characteristics from '../data/characteristics.json'

const ItemEdit = ({ editedItem, index, category, appFiles, setAppFiles }) => {
    const qualities = JSON.parse(appFiles.qualities)
    const talents = JSON.parse(appFiles.talents)
    const filteredQualities = qualities.filter(q => !q.Type || (q.Type && (category === "weapon" ? ["Weapon", "Gear"] : ["Armor", "Gear"]).includes(q.Type)))

    const handleEdit = (newItem) => {
        let newFiles = { ...appFiles };
        let newCat = JSON.parse(appFiles[category]);
        newCat[index] = newItem;
        newFiles[category] = JSON.stringify(newCat);
        setAppFiles(newFiles);
        localStorage.setItem('appFiles', JSON.stringify(newFiles));
    };

    if (editedItem.BaseMods && !Array.isArray(editedItem.BaseMods)) { editedItem.BaseMods = [editedItem.BaseMods] }
    let foundAdversary = editedItem.Profile

    return (
        <Paper>
            <TextField
                sx={{
                    marginTop: "10px",
                    width: "50%",
                }}
                label="Price"
                value={editedItem?.Price || 0}
                onChange={(e) =>
                    handleEdit({ ...editedItem, Price: e.target.value })
                }
            />
            <TextField
                sx={{
                    marginTop: "10px",
                    width: "50%",
                }}
                label="Rarity"
                value={editedItem?.Rarity || ""}
                onChange={(e) =>
                    handleEdit({ ...editedItem, Rarity: e.target.value })
                }
            />
            <TextField
                fullWidth
                sx={{
                    marginTop: "10px",
                }}
                label="Encumbrance"
                value={editedItem?.Encumbrance || ""}
                onChange={(e) =>
                    handleEdit({ ...editedItem, Encumbrance: e.target.value })
                }
            />
            {category === "weapons" && (
                <TextField
                    sx={{
                        marginTop: "10px",
                        width: "50%",
                    }}
                    label="Damage"
                    value={editedItem?.Damage || ""}
                    onChange={(e) =>
                        handleEdit({ ...editedItem, Damage: e.target.value })
                    }
                />
            )}
            {category === "weapons" && (
                <TextField
                    sx={{
                        marginTop: "10px",
                        width: "50%",
                    }}
                    label="Crit"
                    value={editedItem?.Crit || ""}
                    onChange={(e) =>
                        handleEdit({ ...editedItem, Crit: e.target.value })
                    }
                />
            )}
            {category === "weapons" && (
                <TextField
                    fullWidth
                    sx={{
                        marginTop: "10px",
                    }}
                    label="Range"
                    value={editedItem?.Range || ""}
                    onChange={(e) =>
                        handleEdit({ ...editedItem, Range: e.target.value })
                    }
                />
            )}
            {category === "armor" && (
                <TextField
                    sx={{
                        marginTop: "10px",
                        width: "50%",
                    }}
                    label="Soak"
                    value={editedItem?.Soak || ""}
                    onChange={(e) =>
                        handleEdit({ ...editedItem, Soak: e.target.value })
                    }
                />
            )}
            {category === "armor" && (
                <TextField
                    sx={{
                        marginTop: "10px",
                        width: "50%",
                    }}
                    label="Defense"
                    value={editedItem?.Defense || ""}
                    onChange={(e) =>
                        handleEdit({ ...editedItem, Defense: e.target.value })
                    }
                />
            )}
            {(category === "armor" || category === "weapons") && (
                <TextField
                    fullWidth
                    sx={{
                        marginTop: "10px",
                    }}
                    label="HP"
                    value={editedItem?.HP || ""}
                    onChange={(e) =>
                        handleEdit({ ...editedItem, HP: e.target.value })
                    }
                />
            )}
            {(category === "gear" || category === "weapons") && (
                <TextField
                    fullWidth
                    sx={{
                        marginTop: "10px",
                    }}
                    label="Type"
                    value={editedItem?.Type || ""}
                    onChange={(e) =>
                        handleEdit({ ...editedItem, Type: e.target.value })
                    }
                />
            )}
            {editedItem.Qualities && (
                <>
                    <Typography>Qualities:</Typography>
                    <Grid container>
                        {editedItem.Qualities.map((quality, index) => (
                            <Grid item key={index} sx={{ margin: "7.5px" }}>
                                <TextField
                                    label="Quality"
                                    value={qualities.find(q => q.Key === quality.Key)?.QualDesc?.replace('{0}', '') || quality.Key || ''}
                                    onChange={(e) => {
                                        let newQualities = [...editedItem.Qualities];
                                        newQualities[index].Key = e.target.value;
                                        handleEdit({ ...editedItem, Qualities: newQualities });
                                    }}
                                    sx={{ maxWidth: "180px" }}
                                />
                                <TextField
                                    label="Rating"
                                    value={quality.Count || ''}
                                    onChange={(e) => {
                                        let newQualities = [...editedItem.Qualities];
                                        newQualities[index].Count = e.target.value;
                                        handleEdit({ ...editedItem, Qualities: newQualities });
                                    }}
                                    sx={{ maxWidth: "80px" }}
                                />
                                <IconButton onClick={(e) => {
                                    let newQualities = [...editedItem.Qualities];
                                    newQualities.splice(index, 1)
                                    handleEdit({ ...editedItem, Qualities: newQualities });
                                }}
                                    sx={{ maxWidth: "30px" }}
                                    size="small"
                                    className="no-print"
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Grid>
                        ))}
                    </Grid>
                    <Button onClick={(e) => {
                        let newQualities = [...editedItem.Qualities];
                        newQualities.push({})
                        handleEdit({ ...editedItem, Qualities: newQualities });
                    }}
                        className="no-print"
                    >
                        Add
                    </Button>
                </>
            )}
            <Typography>Base Mods:</Typography>
            {editedItem.BaseMods && (
                <Grid container>
                    {editedItem.BaseMods.map((mod, index) => (
                        <Grid item key={index} sx={{ margin: "7.5px" }}>
                            {(mod.MiscDesc || mod.MiscDesc === "") &&
                                <>
                                    <Typography>Custom Mod:</Typography>
                                    <TextField
                                        label="Description"
                                        sx={{ minWidth: "500px" }}
                                        value={mod.MiscDesc}
                                        onChange={(e) => {
                                            let newMods = [...editedItem.BaseMods];
                                            newMods[index].MiscDesc = e.target.value;
                                            handleEdit({ ...editedItem, BaseMods: newMods });
                                        }}
                                    />
                                </>
                            }
                            {mod.Key && skills.find(s => s.Key === mod.Key) &&
                                <>
                                    <Typography>Skill:</Typography>
                                    <Select
                                        value={mod.Key || ''}
                                        onChange={(e) => {
                                            let newMods = [...editedItem.BaseMods];
                                            newMods[index].Key = e.target.value;
                                            handleEdit({ ...editedItem, BaseMods: newMods });
                                        }}
                                    >
                                        {skills.sort((a, b) => a.skill.localeCompare(b.skill)).map((skill) => (
                                            <MenuItem key={skill.Key} value={skill.Key}>
                                                {skill.skill}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <TextField
                                        label="Ranks"
                                        value={mod.Count || 0}
                                        onChange={(e) => {
                                            let newMods = [...editedItem.BaseMods];
                                            newMods[index].Count = !isNaN(e.target.value) ? e.target.value : newMods[index].Count || 0;
                                            handleEdit({ ...editedItem, BaseMods: newMods });
                                        }}
                                        sx={{ maxWidth: "90px" }}
                                    />
                                    {" "} Make Career:
                                    <Checkbox
                                        checked={!!mod.SkillIsCareer}
                                        onChange={(e) => {
                                            let newMods = [...editedItem.BaseMods];
                                            newMods[index].SkillIsCareer = e.target.checked;
                                            handleEdit({ ...editedItem, BaseMods: newMods });
                                        }}
                                    />
                                </>
                            }
                            {mod.Key && talents.find(t => t.Key === mod.Key) &&
                                <>
                                    <Typography>Innate Talent:</Typography>
                                    <Select
                                        value={mod.Key || ''}
                                        onChange={(e) => {
                                            let newMods = [...editedItem.BaseMods];
                                            newMods[index].Key = e.target.value;
                                            handleEdit({ ...editedItem, BaseMods: newMods });
                                        }}
                                    >
                                        {talents.sort((a, b) => a.Name.localeCompare(b.Name)).map((talent) => (
                                            <MenuItem key={talent.Key} value={talent.Key}>
                                                {talent.Name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <TextField
                                        label="Ranks"
                                        value={mod.Count || 0}
                                        onChange={(e) => {
                                            let newMods = [...editedItem.BaseMods];
                                            newMods[index].Count = !isNaN(e.target.value) ? e.target.value : newMods[index].Count || 0;
                                            handleEdit({ ...editedItem, BaseMods: newMods });
                                        }}
                                        sx={{ maxWidth: "90px" }}
                                    />
                                </>
                            }
                            {mod.Key && characteristics.find(t => t.Key === mod.Key) &&
                                <>
                                    <Typography>Characteristic Mod:</Typography>
                                    <Select
                                        value={mod.Key || ''}
                                        onChange={(e) => {
                                            let newMods = [...editedItem.BaseMods];
                                            newMods[index].Key = e.target.value;
                                            handleEdit({ ...editedItem, BaseMods: newMods });
                                        }}
                                        sx={{ maxWidth: "180px" }}
                                    >
                                        {characteristics.map((characteristic) => (
                                            <MenuItem key={characteristic.Key} value={characteristic.Key}>
                                                {characteristic.Name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <TextField
                                        label="Ranks"
                                        value={mod.Count || 0}
                                        onChange={(e) => {
                                            let newMods = [...editedItem.BaseMods];
                                            newMods[index].Count = !isNaN(e.target.value) ? e.target.value : newMods[index].Count || 0;
                                            handleEdit({ ...editedItem, BaseMods: newMods });
                                        }}
                                        sx={{ maxWidth: "90px" }}
                                    />
                                </>
                            }
                            {mod.Key && qualities.find(t => t.Key === mod.Key) &&
                                <>
                                    <Typography>Quality:</Typography>
                                    <Select
                                        value={mod.Key || ''}
                                        onChange={(e) => {
                                            let newMods = [...editedItem.BaseMods];
                                            newMods[index].Key = e.target.value;
                                            handleEdit({ ...editedItem, BaseMods: newMods });
                                        }}
                                    >
                                        {filteredQualities.sort((a, b) => a.Name.localeCompare(b.Name)).map((quality) => (
                                            <MenuItem key={quality.Key} value={quality.Key}>
                                                {quality.Name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <TextField
                                        label="Ranks"
                                        value={mod.Count || 0}
                                        onChange={(e) => {
                                            let newMods = [...editedItem.BaseMods];
                                            newMods[index].Count = !isNaN(e.target.value) ? e.target.value : newMods[index].Count || 0;
                                            handleEdit({ ...editedItem, BaseMods: newMods });
                                        }}
                                        sx={{ maxWidth: "90px" }}
                                    />
                                </>
                            }
                            <IconButton onClick={(e) => {
                                let newMods = [...editedItem.BaseMods];
                                newMods.splice(index, 1)
                                handleEdit({ ...editedItem, BaseMods: newMods });
                            }}
                                sx={{ maxWidth: "30px" }}
                                size="small"
                                className="no-print"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    ))}
                </Grid>
            )}
            <Button onClick={(e) => {
                let newMods = [...editedItem.BaseMods || []];
                newMods.push({ MiscDesc: "" })
                handleEdit({ ...editedItem, BaseMods: newMods });
            }}
                className="no-print"
            >
                Add Custom Mod
            </Button>
            <Button onClick={(e) => {
                let newMods = [...editedItem.BaseMods];
                newMods.push({ Key: "BR" })
                handleEdit({ ...editedItem, BaseMods: newMods });
            }}
                className="no-print"
            >
                Add Characteristic Mod
            </Button>
            <Button onClick={(e) => {
                let newMods = [...editedItem.BaseMods];
                newMods.push({ Key: "ASTRO" })
                handleEdit({ ...editedItem, BaseMods: newMods });
            }}
                className="no-print"
            >
                Add Skill Mod
            </Button>
            <Button onClick={(e) => {
                let newMods = [...editedItem.BaseMods];
                newMods.push({ Key: "ADV" })
                handleEdit({ ...editedItem, BaseMods: newMods });
            }}
                className="no-print"
            >
                Add Talent Mod
            </Button>
            <Button onClick={(e) => {
                let newMods = [...editedItem.BaseMods];
                newMods.push({ Key: filteredQualities[0].Key })
                handleEdit({ ...editedItem, BaseMods: newMods });
            }}
                className="no-print"
            >
                Add Quality
            </Button>
            {foundAdversary && (<>
                <Grid container>
                    <Grid item xs={2}>
                        <TextField
                            fullWidth
                            sx={{
                                marginTop: "10px",
                            }}
                            label="Brawn"
                            value={editedItem?.Profile?.Characteristics?.BR || 1}
                            onChange={(e) =>
                                handleEdit({ ...editedItem, Profile: { ...editedItem.Profile, Characteristics: { ...editedItem.Profile.Characteristics, BR: e.target.value } } })
                            }
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            fullWidth
                            sx={{
                                marginTop: "10px",
                            }}
                            label="Agility"
                            value={editedItem?.Profile?.Characteristics?.AG || 1}
                            onChange={(e) =>
                                handleEdit({ ...editedItem, Profile: { ...editedItem.Profile, Characteristics: { ...editedItem.Profile.Characteristics, AG: e.target.value } } })
                            }
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            fullWidth
                            sx={{
                                marginTop: "10px",
                            }}
                            label="Intellect"
                            value={editedItem?.Profile?.Characteristics?.INT || 1}
                            onChange={(e) =>
                                handleEdit({ ...editedItem, Profile: { ...editedItem.Profile, Characteristics: { ...editedItem.Profile.Characteristics, INT: e.target.value } } })
                            }
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            fullWidth
                            sx={{
                                marginTop: "10px",
                            }}
                            label="Cunning"
                            value={editedItem?.Profile?.Characteristics?.CUN || 1}
                            onChange={(e) =>
                                handleEdit({ ...editedItem, Profile: { ...editedItem.Profile, Characteristics: { ...editedItem.Profile.Characteristics, CUN: e.target.value } } })
                            }
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            fullWidth
                            sx={{
                                marginTop: "10px",
                            }}
                            label="Willpower"
                            value={editedItem?.Profile?.Characteristics?.WIL || 1}
                            onChange={(e) =>
                                handleEdit({ ...editedItem, Profile: { ...editedItem.Profile, Characteristics: { ...editedItem.Profile.Characteristics, WIL: e.target.value } } })
                            }
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            fullWidth
                            sx={{
                                marginTop: "10px",
                            }}
                            label="Presence"
                            value={editedItem?.Profile?.Characteristics?.PR || 1}
                            onChange={(e) =>
                                handleEdit({ ...editedItem, Profile: { ...editedItem.Profile, Characteristics: { ...editedItem.Profile.Characteristics, PR: e.target.value } } })
                            }
                        />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={3}>
                        <TextField
                            fullWidth
                            sx={{
                                marginTop: "10px",
                            }}
                            label="Wound Threshold"
                            value={editedItem?.Profile?.Attributes?.WoundThreshold || 0}
                            onChange={(e) =>
                                handleEdit({ ...editedItem, Profile: { ...editedItem.Profile, Attributes: { ...editedItem.Profile.Attributes, WoundThreshold: e.target.value } } })
                            }
                        />
                    </Grid>
                    {(editedItem?.Profile?.Attributes?.StrainThreshold) &&
                        <Grid item xs={3}>
                            <TextField
                                fullWidth
                                sx={{
                                    marginTop: "10px",
                                }}
                                label="Strain Threshold"
                                value={editedItem?.Profile?.Attributes?.StrainThreshold || 0}
                                onChange={(e) =>
                                    handleEdit({ ...editedItem, Profile: { ...editedItem.Profile, Attributes: { ...editedItem.Profile.Attributes, StrainThreshold: e.target.value } } })
                                }
                            />
                        </Grid>}
                    <Grid item xs={3}>
                        <TextField
                            fullWidth
                            sx={{
                                marginTop: "10px",
                            }}
                            label="Soak"
                            value={editedItem?.Profile?.Attributes?.Soak || 0}
                            onChange={(e) =>
                                handleEdit({ ...editedItem, Profile: { ...editedItem.Profile, Attributes: { ...editedItem.Profile.Attributes, Soak: e.target.value } } })
                            }
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Grid container>
                            <Grid item xs={4} container alignItems="center" justifyContent="center">
                                <Typography>Defense:</Typography>
                            </Grid>

                            <Grid item xs={4}>
                                <TextField
                                    fullWidth
                                    sx={{
                                        marginTop: "10px",
                                    }}
                                    label="Ranged"
                                    value={
                                        (editedItem?.Profile?.Attributes?.Defense &&
                                            editedItem.Profile.Attributes.Defense[0]) ||
                                        0
                                    }
                                    onChange={(e) =>
                                        handleEdit({
                                            ...editedItem,
                                            Profile: {
                                                ...editedItem.Profile,
                                                Attributes: {
                                                    ...editedItem.Profile.Attributes,
                                                    Defense: [
                                                        e.target.value,
                                                        editedItem?.Profile?.Attributes?.Defense?.[1] || 0,
                                                    ],
                                                },
                                            },
                                        })
                                    }
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    fullWidth
                                    sx={{
                                        marginTop: "10px",
                                    }}
                                    label="Melee"
                                    value={
                                        (editedItem?.Profile?.Attributes?.Defense &&
                                            editedItem.Profile.Attributes.Defense[1]) ||
                                        0
                                    }
                                    onChange={(e) =>
                                        handleEdit({
                                            ...editedItem,
                                            Profile: {
                                                ...editedItem.Profile,
                                                Attributes: {
                                                    ...editedItem.Profile.Attributes,
                                                    Defense: [
                                                        editedItem?.Profile?.Attributes?.Defense?.[0] || 0,
                                                        e.target.value,
                                                    ],
                                                },
                                            },
                                        })
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {foundAdversary.Skills && (
                    <>
                        <Typography sx={{ marginTop: "10px", }}>Skills:</Typography>
                        <Grid container>
                            {!Array.isArray(editedItem.Profile.Skills) && <> {Object.entries(editedItem.Profile.Skills)
                                .sort(([a], [b]) => a.localeCompare(b))
                                .map(([skillName, ranks], index) => (
                                    <Grid item key={index} sx={{ margin: "7.5px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <TextField
                                            label="Skill"
                                            value={skillName || ''}
                                            onChange={(e) => {
                                                const newSkills = { ...editedItem.Profile.Skills };
                                                const newSkillName = e.target.value;
                                                newSkills[newSkillName] = newSkills[skillName];
                                                delete newSkills[skillName];
                                                handleEdit({ ...editedItem, Profile: { ...editedItem.Profile, Skills: newSkills } });
                                            }}
                                            sx={{ maxWidth: "180px" }}
                                        />
                                        <TextField
                                            label="Ranks"
                                            value={ranks || ''}
                                            onChange={(e) => {
                                                const newSkills = { ...editedItem.Profile.Skills };
                                                newSkills[skillName] = parseInt(e.target.value) || 0;
                                                handleEdit({ ...editedItem, Profile: { ...editedItem.Profile, Skills: newSkills } });
                                            }}
                                            sx={{ maxWidth: "80px" }}
                                        />
                                        <IconButton
                                            onClick={() => {
                                                const newSkills = { ...editedItem.Profile.Skills };
                                                delete newSkills[skillName];
                                                handleEdit({ ...editedItem, Profile: { ...editedItem.Profile, Skills: newSkills } });
                                            }}
                                            sx={{ maxWidth: "30px" }}
                                            size="small"
                                            className="no-print"
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </Grid>
                                ))}
                                <Button
                                    onClick={() => {
                                        const newSkills = { ...editedItem.Profile.Skills };
                                        newSkills["New Skill"] = 0;
                                        handleEdit({
                                            ...editedItem,
                                            Profile: { ...editedItem.Profile, Skills: newSkills }
                                        });
                                    }}
                                    className="no-print"
                                >
                                    Add
                                </Button>
                            </>
                            }
                            {Array.isArray(editedItem.Profile.Skills) && <> {editedItem.Profile.Skills.map((skill, index) => (
                                <Grid item key={index} sx={{ margin: "7.5px" }}>
                                    <TextField
                                        label="Skill"
                                        value={skill || ''}
                                        onChange={(e) => {
                                            let newSkills = [...editedItem.Profile.Skills];
                                            newSkills[index] = e.target.value;
                                            handleEdit({ ...editedItem, Profile: { ...editedItem.Profile, Skills: newSkills } });
                                        }}
                                        sx={{ maxWidth: "180px" }}
                                    />
                                    <IconButton onClick={(e) => {
                                        let newSkills = [...editedItem.Skills];
                                        newSkills.splice(index, 1)
                                        handleEdit({ ...editedItem, Profile: { ...editedItem.Profile, Skills: newSkills } });
                                    }}
                                        sx={{ maxWidth: "30px" }}
                                        size="small"
                                        className="no-print"
                                    >
                                        <CloseIcon />
                                    </IconButton>

                                </Grid>
                            ))}
                                <Button
                                    onClick={() => {
                                        const newSkills = [...editedItem.Profile.Skills];
                                        newSkills.push("")
                                        handleEdit({
                                            ...editedItem,
                                            Profile: { ...editedItem.Profile, Skills: newSkills }
                                        });
                                    }}
                                    className="no-print"
                                >
                                    Add
                                </Button>
                            </>
                            }
                        </Grid>
                    </>
                )}
                {foundAdversary.Talents && (
                    <>
                        <Typography sx={{ marginTop: "10px", }}>Talents:</Typography>
                        {foundAdversary.Talents.map((talent, index) => (
                            <>
                                <TextField
                                    key={index}
                                    label="Talent"
                                    value={talent.Key ? talents.find((t) => t.Key === talent.Key).Name + " " + talent.Ranks : talent}
                                    onChange={(e) => {
                                        const newTalents = [...editedItem.Profile.Talents];
                                        newTalents[index] = e.target.value;
                                        handleEdit({
                                            ...editedItem,
                                            Profile: { ...editedItem.Profile, Talents: newTalents },
                                        });
                                    }}
                                />
                                <IconButton
                                    onClick={() => {
                                        const newTalents = [...editedItem.Profile.Talents]
                                        newTalents.splice(index, 1)
                                        handleEdit({ ...editedItem, Profile: { ...editedItem.Profile, Talents: newTalents } });
                                    }}
                                    sx={{ maxWidth: "30px" }}
                                    size="small"
                                    className="no-print"
                                >
                                    <CloseIcon />
                                </IconButton>
                            </>
                        ))}
                        <Button
                            onClick={() => {
                                const newTalents = [...editedItem.Profile.Talents];
                                newTalents.push("")
                                handleEdit({
                                    ...editedItem,
                                    Profile: { ...editedItem.Profile, Talents: newTalents }
                                });
                            }}
                            className="no-print"
                        >
                            Add
                        </Button>

                    </>
                )}
                {foundAdversary.Abilities && (
                    <>
                        <Typography sx={{ marginTop: "10px", }}>Abilities:</Typography>
                        {foundAdversary.Abilities.map((ability, index) => (
                            <>
                                <TextField
                                    key={index}
                                    label="Ability"
                                    value={ability.Name ? ability.Name + ": " + ability.Description : ability}
                                    onChange={(e) => {
                                        const newAbilities = [...editedItem.Profile.Abilities];
                                        newAbilities[index] = e.target.value;
                                        handleEdit({
                                            ...editedItem,
                                            Profile: { ...editedItem.Profile, Abilities: newAbilities },
                                        });
                                    }}
                                />
                                <IconButton
                                    onClick={() => {
                                        const newAbilities = [...editedItem.Profile.Abilities]
                                        newAbilities.splice(index, 1)
                                        handleEdit({ ...editedItem, Profile: { ...editedItem.Profile, Abilities: newAbilities } });
                                    }}
                                    sx={{ maxWidth: "30px" }}
                                    size="small"
                                    className="no-print"
                                >
                                    <CloseIcon />
                                </IconButton>
                            </>
                        ))}
                        <Button
                            onClick={() => {
                                const newAbilities = [...editedItem.Profile.Abilities];
                                newAbilities.push("")
                                handleEdit({
                                    ...editedItem,
                                    Profile: { ...editedItem.Profile, Abilities: newAbilities }
                                });
                            }}
                            className="no-print"
                        >
                            Add
                        </Button>

                    </>
                )}
                <>
                    <Grid contianer>
                        <Grid item xs={6}>
                            <Typography sx={{ marginTop: "10px", }}>Weapons:</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                onClick={() => {
                                    const newWeapons = [...editedItem.Profile.Weapons];
                                    newWeapons.push({})
                                    handleEdit({
                                        ...editedItem,
                                        Profile: { ...editedItem.Profile, Weapons: newWeapons }
                                    });
                                }}
                                className="no-print"
                            >
                                Add
                            </Button>
                        </Grid>
                    </Grid>
                    {(foundAdversary.Weapons || []).map((weaponObject, index) => {
                        let weapon = typeof weaponObject === 'object' ? weaponObject : weapons.find(w => w.Key === weaponObject);
                        return (
                            <Paper sx={{ marginTop: "10px" }}>
                                <Grid container spacing={1}>
                                    <Grid item xs={1} sm={1} md={.2} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <IconButton
                                            onClick={() => {
                                                const newWeapons = [...editedItem.Profile.Weapons || []]
                                                newWeapons.splice(index, 1)
                                                handleEdit({ ...editedItem, Profile: { ...editedItem.Profile, Weapons: newWeapons } });
                                            }}
                                            sx={{ maxWidth: "30px" }}
                                            size="small"
                                            className="no-print"
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </Grid>
                                    <Grid item xs={11} sm={4} md={1} lg={1}>
                                        <TextField
                                            key={index}
                                            label="Name"
                                            value={weapon.Name || ''}
                                            onChange={(e) => {
                                                const newWeapons = [...editedItem.Profile.Weapons];
                                                weapon.Name = e.target.value
                                                newWeapons[index] = weapon
                                                handleEdit({
                                                    ...editedItem,
                                                    Profile: { ...editedItem.Profile, Weapons: newWeapons },
                                                });
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={1} lg={1}>
                                        <TextField
                                            key={index}
                                            label="Skill"
                                            value={weapon.Skill || skills.find((s) => s.Key === weapon.SkillKey)?.skill || ''}
                                            onChange={(e) => {
                                                const newWeapons = [...editedItem.Profile.Weapons];
                                                weapon.Skill = e.target.value;
                                                newWeapons[index] = weapon
                                                handleEdit({
                                                    ...editedItem,
                                                    Profile: { ...editedItem.Profile, Weapons: newWeapons },
                                                });
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={1} lg={1}>
                                        <TextField
                                            key={index}
                                            label="Damage"
                                            value={weapon.Damage || ''}
                                            onChange={(e) => {
                                                const newWeapons = [...editedItem.Profile.Weapons];
                                                weapon.Damage = e.target.value;
                                                newWeapons[index] = weapon
                                                handleEdit({
                                                    ...editedItem,
                                                    Profile: { ...editedItem.Profile, Weapons: newWeapons },
                                                });
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={1} lg={1}>
                                        <TextField
                                            key={index}
                                            label="Crit"
                                            value={weapon.Crit || ''}
                                            onChange={(e) => {
                                                const newWeapons = [...editedItem.Profile.Weapons];
                                                weapon.Crit = e.target.value;
                                                newWeapons[index] = weapon
                                                handleEdit({
                                                    ...editedItem,
                                                    Profile: { ...editedItem.Profile, Weapons: newWeapons },
                                                });
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={1} lg={1}>
                                        <TextField
                                            key={index}
                                            label="Range"
                                            value={weapon.Range || ''}
                                            onChange={(e) => {
                                                const newWeapons = [...editedItem.Profile.Weapons];
                                                weapon.Range = e.target.value;
                                                newWeapons[index] = weapon
                                                handleEdit({
                                                    ...editedItem,
                                                    Profile: { ...editedItem.Profile, Weapons: newWeapons },
                                                });
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={1} lg={1} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <Typography>Qualities:</Typography>
                                    </Grid>
                                    {(weapon.Qualities || []).map((quality, i) => (
                                        <>
                                            <Grid item xs={12} sm={4} md={1} lg={1} key={i}>
                                                <TextField
                                                    label="Quality"
                                                    value={quality.Key ? qualities.find((q) => q.Key === quality.Key).Name.replace("Quality", "") + (quality.Count || '') : quality || ''}
                                                    onChange={(e) => {
                                                        const newWeapons = [...editedItem.Profile.Weapons];
                                                        weapon.Qualities[i] = e.target.value;
                                                        newWeapons[index] = weapon
                                                        handleEdit({
                                                            ...editedItem,
                                                            Profile: { ...editedItem.Profile, Weapons: newWeapons },
                                                        });
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={1} sm={1} md={.2} >
                                                <IconButton
                                                    onClick={() => {
                                                        const newWeapons = [...editedItem.Profile.Weapons]
                                                        newWeapons[index].Qualities.splice(i, 1)
                                                        handleEdit({ ...editedItem, Profile: { ...editedItem.Profile, Weapons: newWeapons } });
                                                    }}
                                                    sx={{ maxWidth: "30px" }}
                                                    size="small"
                                                    className="no-print"
                                                >
                                                    <CloseIcon />
                                                </IconButton>
                                            </Grid>
                                        </>
                                    ))}
                                    <Grid item xs={1}>
                                        <Button
                                            onClick={() => {
                                                const newWeapons = [...editedItem.Profile.Weapons];
                                                newWeapons[index].Qualities.push('')
                                                handleEdit({
                                                    ...editedItem,
                                                    Profile: { ...editedItem.Profile, Weapons: newWeapons }
                                                });
                                            }}
                                            className="no-print"
                                        >
                                            Add
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Paper>
                        )
                    })}

                </>
                <TextField
                    fullWidth
                    sx={{ marginTop: "10px", }}
                    label="Gear"
                    value={foundAdversary.Gear || ''}
                    onChange={(e) => {
                        let newGear = [...editedItem.Profile.Gear || ''];
                        newGear = e.target.value;
                        handleEdit({
                            ...editedItem,
                            Profile: { ...editedItem.Profile, Gear: newGear },
                        });
                    }}
                />
            </>)
            }
        </Paper>
    )
}

export default ItemEdit