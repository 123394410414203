import React, { useState, useEffect, useMemo } from "react";
import {
  useMediaQuery,
  Typography,
  Tabs,
  Tab,
  Paper,
  List,
  ListItem,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  Modal,
  Container,
  Checkbox,
  FormControlLabel,
  Card,
  CardMedia,
  IconButton
} from "@mui/material";
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterListOff from '@mui/icons-material/FilterListOff';
import gearData from "./data/gear.json";
import armorData from "./data/armor.json";
import weaponsData from "./data/weapons.json";
import skillsData from "./data/skills.json";
import qualitiesData from "./data/qualities.json";
import attachmentsData from "./data/attachments.json";
import talentsData from "./data/talents.json";
import craftingTemplates from "./data/craftingTemplates.json"
import craftingResultTables from "./data/craftingResultTables.json"
import FuzzySearch from "fuzzy-search";
import MarkedText from "./Util/MarkedText";
import formatSource from "./Util/FormatSource";
import getModText from "./Util/GetModText";
import editQualities from "./Util/EditQualities";
import CraftingModal from "./Util/CraftingModal";
import DroidCraftingModal from "./Util/DroidCraftingModal";
import qualitiesString from "./Util/QualitiesString";
import StatForm from "./Util/StatForm";
import FilterModal from "./Util/FilterModal";

let gear = gearData
let armor = armorData
let weapons = weaponsData
let skills = skillsData
let qualities = qualitiesData
let attachments = attachmentsData
let talents = talentsData

function BuySellModal({ item, open, onClose, onSubmit, transaction, tabValue }) {
  const [price, setPrice] = useState(item.Price || 0);
  const [percentage, setPercentage] = useState(100);
  const [quantity, setQuantity] = useState(1);

  useEffect(() => {
    setPrice(item.Price || 0);
  }, [item.Price, open]);

  const handleChangePrice = (event) => {
    const newPrice = Math.ceil(event.target.value);
    setPrice(newPrice);
    setPercentage((newPrice * 100) / item.Price);
  };

  const handleChangePercentage = (event) => {
    const newPercentage = Math.ceil(event.target.value);
    setPercentage(newPercentage);
    setPrice(Math.ceil((item.Price * newPercentage) / 100));
  };

  const handleChangeQuantity = (event) => {
    const newQuant = Math.ceil(event.target.value);
    setQuantity(newQuant);
  };

  const handleSubmit = () => {
    onSubmit({ ...item, Price: price, Quantity: quantity });
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Paper
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          padding: "25px",
        }}
      >
        <Typography variant="h6">
          {transaction} {item.Name}
        </Typography>
        <TextField
          label="Price"
          type="number"
          value={price}
          onChange={handleChangePrice}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Percent"
          type="number"
          value={percentage}
          onChange={handleChangePercentage}
          fullWidth
          margin="normal"
        />
        {tabValue === 2 && <TextField
          label="Quantity"
          type="number"
          value={quantity}
          onChange={handleChangeQuantity}
          fullWidth
          margin="normal"
        />}
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Confirm
        </Button>
      </Paper>
    </Modal>
  );
}

function EquipmentTable(props) {
  const {
    equipmentData,
    tabValue,
    handleBuyItem,
    handleSellItem,
    setPreviewItem,
    setPreviewOwned,
    owned,
    sortBy,
    setSortBy,
  } = props;

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  const [selectedType, setSelectedType] = useState("");
  const [availableTypes, setAvailableTypes] = useState([]);
  const [search, setSearch] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  const [transactionType, setTransactionType] = useState(null);
  const [filterModalOpen, setFilterModalOpen] = useState(false)
  const [filter, setFilter] = useState("")

  const handleOpenModal = (item, transaction) => {
    setSelectedItem(item);
    setTransactionType(transaction);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedItem(null);
  };

  const handleSubmitModal = (item, tab, transaction) => {
    if (transaction === "Buy") {
      handleBuyItem(item, tab);
    }
    if (transaction === "Sell") {
      handleSellItem(item, tab);
    }
    handleCloseModal();
  };

  useEffect(() => {
    const newTypes = types(equipmentData[tabValue]) || [];
    setAvailableTypes(newTypes);

    if (!newTypes.includes(selectedType)) {
      setSelectedType("");
    }
  }, [equipmentData, tabValue]);

  const types = (cat) => {
    const typeSet = new Set();
    for (let item in cat.items) {
      if (cat.items[item].Type) {
        typeSet.add(cat.items[item].Type);
      }
    }
    let types = Array.from(typeSet);
    types.sort(function (a, b) {
      return a > b;
    });
    return types;
  };

  const handleSortClick = (field) => {
    setSortBy({
      field,
      direction:
        sortBy.field === field
          ? sortBy.direction === "asc"
            ? "desc"
            : "asc"
          : "asc",
    });
  };

  const sortData = (data) => {
    if (!sortBy.field) return data;

    const primarySortField = sortBy.field;
    const secondarySortField = sortBy.field + "Add";

    return data.sort((a, b) => {
      const sortDirection = sortBy.direction === "asc" ? 1 : -1;

      // Check if the sort fields exists on both objects
      const hasAPrimarySortField = primarySortField in a;
      const hasBPrimarySortField = primarySortField in b;
      const hasASecondarySortField = secondarySortField in a;
      const hasBSecondarySortField = secondarySortField in b;

      //no sorting when none of the fields exist
      if (
        !hasAPrimarySortField &&
        !hasBPrimarySortField &&
        !hasASecondarySortField &&
        !hasBSecondarySortField
      ) {
        return 0;
      }

      // Compare using the primary sort field if it exists on both objects
      if (hasAPrimarySortField && hasBPrimarySortField) {
        let valueA = a[primarySortField].toString().replace(/['" ]+/g, "");
        let valueB = b[primarySortField].toString().replace(/['" ]+/g, "");

        // Check if both values are integers after removing special characters
        const isValueANumber = !isNaN(parseInt(valueA)) && isFinite(valueA);
        const isValueBNumber = !isNaN(parseInt(valueB)) && isFinite(valueB);

        if (isValueANumber && isValueBNumber) {
          // Both values are integers, sort numerically
          return (parseInt(valueA) - parseInt(valueB)) * sortDirection;
        } else {
          // At least one value is not a number, sort as strings
          valueA = valueA.toLowerCase();
          valueB = valueB.toLowerCase();

          if (valueA < valueB) {
            return -sortDirection;
          } else if (valueA > valueB) {
            return sortDirection;
          }
        }
      }
      //If only one object has primarySort, prioritize that one
      if (hasAPrimarySortField) {
        return sortDirection;
      }
      if (hasBPrimarySortField) {
        return -sortDirection;
      }
      // If the primary sort field doesn't exist on both objects, compare using the secondary sort field
      let valueA = (
        hasAPrimarySortField ? a[primarySortField] : a[secondarySortField]
      )
        .toString()
        .replace(/['" ]+/g, "");
      let valueB = (
        hasBPrimarySortField ? b[primarySortField] : b[secondarySortField]
      )
        .toString()
        .replace(/['" ]+/g, "");

      // Check if both values are integers after removing special characters
      const isValueANumber = !isNaN(parseInt(valueA)) && isFinite(valueA);
      const isValueBNumber = !isNaN(parseInt(valueB)) && isFinite(valueB);
      if (isValueANumber && isValueBNumber) {
        // Both values are integers, sort numerically
        return (parseInt(valueA) - parseInt(valueB)) * sortDirection;
      } else {
        // At least one value is not a number, sort as strings
        valueA = valueA.toLowerCase();
        valueB = valueB.toLowerCase();

        if (valueA < valueB) {
          return -sortDirection;
        } else if (valueA > valueB) {
          return sortDirection;
        }
      }

      return 0;
    });
  };

  const sortedData = () => {
    let data = equipmentData[tabValue].items;
    if (selectedType) {
      data = data.filter((item) => item.Type === selectedType);
    }
    if (filter) {
      data = data.filter(filter)
    }

    const sorted = sortData(data);
    return sorted;
  };

  const searcher = new FuzzySearch(sortedData(), ["Name", "Type"], {
    caseSensitive: false,
  });

  const searchResults = searcher.search(search);

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={5}>
          <TextField
            fullWidth
            label="Search"
            value={search}
            onChange={(event) => setSearch(event.target.value)}
            size={isLargeScreen ? 'medium' : 'small'}
          />
        </Grid>
        <Grid item xs={10} sm={5}>
          {tabValue != 1 && (
            <FormControl fullWidth>
              <InputLabel
                sx={{
                  transform: selectedType ? 'translate(14px, -10px)' : isLargeScreen ? 'translate(14px, 14px)' : 'translate(14px, 9px)',
                  transition: 'transform 200ms ease-in-out',
                  fontSize: (!isLargeScreen && selectedType) ? '0.875rem' : '1rem',
                }}
              >
                Type
              </InputLabel>
              <Select
                value={selectedType}
                onChange={(event) => setSelectedType(event.target.value)}
                size={isLargeScreen ? 'medium' : 'small'}
              >
                {availableTypes.map((type, index) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Grid>
        <Grid item xs={2} container justifyContent="center" alignItems="center">
          <IconButton
            onClick={() => setFilterModalOpen(true)}
            sx={{ maxWidth: "30px" }}
            size="small"
            className="no-print"
          >
            <FilterListIcon color={filter ? "primary" : "white"} />
          </IconButton>
          <IconButton
            onClick={() => setFilter("")}
            sx={{ maxWidth: "30px" }}
            size="small"
            className="no-print"
          >
            <FilterListOff />
          </IconButton>
        </Grid>
      </Grid>
      <FilterModal open={filterModalOpen} handleClose={() => setFilterModalOpen(false)} filter={filter} setFilter={setFilter} category={["Weapons", "Armor", "Gear"][tabValue]} data={sortedData()} />
      <TableContainer
        component={Paper}
        style={{
          maxHeight: isLargeScreen ? "calc(100vh - 19.5vh)" : "40vh",
          minHeight: "8vh",
          overflowY: "auto"
        }}
      >
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow hover sx={{ cursor: "pointer" }}>
              <TableCell onClick={() => handleSortClick("Name")}>
                {sortBy.field != "Name" && "Name"}
                {sortBy.field === "Name" && (
                  <div>Name {sortBy.direction === "asc" ? "˅" : "˄"}</div>
                )}
              </TableCell>
              {tabValue === 0 && (
                !isSmallScreen && <TableCell onClick={() => handleSortClick("SkillKey")}>
                  {sortBy.field != "SkillKey" && "Skill"}
                  {sortBy.field === "SkillKey" && (
                    <div>Skill {sortBy.direction === "asc" ? "˅" : "˄"}</div>
                  )}
                </TableCell>
              )}
              {tabValue === 0 && (
                !isSmallScreen && <TableCell onClick={() => handleSortClick("Range")}>
                  {sortBy.field != "Range" && "Range"}
                  {sortBy.field === "Range" && (
                    <div>Range {sortBy.direction === "asc" ? "˅" : "˄"}</div>
                  )}
                </TableCell>
              )}
              {tabValue === 0 && (
                <TableCell align="center" onClick={() => handleSortClick("Damage")}>
                  {sortBy.field != "Damage" && "Dam"}
                  {sortBy.field === "Damage" && (
                    <div>Dam {sortBy.direction === "asc" ? "˅" : "˄"}</div>
                  )}
                </TableCell>
              )}
              {tabValue === 0 && (
                !isSmallScreen && <TableCell align="center" onClick={() => handleSortClick("Crit")}>
                  {sortBy.field != "Crit" && "Crit"}
                  {sortBy.field === "Crit" && (
                    <div>Crit {sortBy.direction === "asc" ? "˅" : "˄"}</div>
                  )}
                </TableCell>
              )}
              {tabValue === 1 && (
                <TableCell align="center" onClick={() => handleSortClick("Soak")}>
                  {sortBy.field != "Soak" && "Soak"}
                  {sortBy.field === "Soak" && (
                    <div>Soak {sortBy.direction === "asc" ? "˅" : "˄"}</div>
                  )}
                </TableCell>
              )}
              {tabValue === 1 && (
                <TableCell align="center" onClick={() => handleSortClick("Defense")}>
                  {sortBy.field != "Defense" && "Def"}
                  {sortBy.field === "Defense" && (
                    <div>Def {sortBy.direction === "asc" ? "˅" : "˄"}</div>
                  )}
                </TableCell>
              )}
              {!(tabValue === 1 && isSmallScreen) && <TableCell align="center" onClick={() => handleSortClick("Encumbrance")}>
                {sortBy.field != "Encumbrance" && "Enc"}
                {sortBy.field === "Encumbrance" && (
                  <div>Enc {sortBy.direction === "asc" ? "˅" : "˄"}</div>
                )}
              </TableCell>}
              <TableCell align="right" onClick={() => handleSortClick("Price")}>
                {sortBy.field != "Price" && "Price"}
                {sortBy.field === "Price" && (
                  <div>Price {sortBy.direction === "asc" ? "˅" : "˄"}</div>
                )}
              </TableCell>
              {tabValue === 2 && (
                owned && <TableCell align="right">
                  <div>Quantity</div>
                </TableCell>
              )}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {searchResults.map((item) => (
              <TableRow
                hover
                key={item.Key}
                onClick={() => {
                  setPreviewItem(item);
                  setPreviewOwned(owned);
                }}
                sx={{ cursor: "pointer" }}
              >
                <TableCell>{item.Name}</TableCell>
                {tabValue === 0 && (
                  !isSmallScreen && <TableCell>
                    {skills.find((s) => s.Key === item.SkillKey).skill}
                  </TableCell>
                )}
                {tabValue === 0 && !isSmallScreen && <TableCell>{item.Range}</TableCell>}
                {tabValue === 0 && (
                  <TableCell align="center">
                    {item.Damage}
                    {item.DamageAdd &&
                      !(item.DamageAdd < 0) &&
                      " + " + item.DamageAdd}
                    {item.DamageAdd < 0 && item.DamageAdd}
                  </TableCell>
                )}
                {tabValue === 0 && (
                  !isSmallScreen && <TableCell align="center">{item.Crit}</TableCell>
                )}
                {tabValue === 1 && (
                  <TableCell align="center">{item.Soak}</TableCell>
                )}
                {tabValue === 1 && (
                  <TableCell align="center">{item.Defense}</TableCell>
                )}
                {!(tabValue === 1 && isSmallScreen) && <TableCell align="center">{item.Encumbrance}</TableCell>}
                <TableCell align="right">{item.Price || "Unique"}</TableCell>
                {tabValue === 2 && (
                  owned && <TableCell align="right">{item.Quantity || 1}</TableCell>
                )}
                <TableCell align="right">
                  {!owned && (
                    <Button
                      size="small"
                      style={{
                        fontSize: "0.7rem",
                        minWidth: "30px",
                      }}
                      variant="contained"
                      color="primary"
                      onClick={() => handleOpenModal(item, "Buy")}
                    >
                      Buy
                    </Button>
                  )}
                  {owned && (
                    <Button
                      size="small"
                      style={{
                        fontSize: "0.7rem",
                        minWidth: "30px",
                      }}
                      variant="contained"
                      color="primary"
                      onClick={() => handleOpenModal(item, "Sell")}
                    >
                      Sell
                    </Button>
                  )}
                  {modalOpen && item.Key === selectedItem.Key && (
                    <BuySellModal
                      item={selectedItem}
                      open={modalOpen}
                      onClose={handleCloseModal}
                      onSubmit={(item) => {
                        handleSubmitModal(item, tabValue, transactionType);
                      }}
                      transaction={transactionType}
                      tabValue={tabValue}
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer >
    </>
  );
}

function Preview(props) {
  const { character, handleCharacterChange, previewItem, setPreviewItem, previewOwned, tabValue, category } = props;
  const [openAttachments, setOpenAttachments] = useState(false);
  const [selectedAttachment, setSelectedAttachment] = useState("");
  const [editedItem, setEditedItem] = useState(null);
  const [modStates, setModStates] = useState({});

  useEffect(() => {
    if (previewItem) {
      setModStates(previewItem.ModStates || {});
    }
  }, [previewItem]);

  const handleOpenAttachments = (item) => {
    setEditedItem(item);
    setOpenAttachments(true);
  };

  const handleCloseAttachments = () => {
    setOpenAttachments(false);
  };

  const handleSaveAttachments = (item, attachment) => {
    let newCategory = [...character[category]];
    if (!item.Attachments) {
      item.Attachments = [];
    }
    if (attachment.AddedMods) {
      attachment.AddedMods.forEach((mod) => {
        setModStates((prevModStates) => ({
          ...prevModStates,
          [`${item.inventoryID}-${attachment.Key}-${mod.Key}`]: {
            installed: Array(parseInt(Math.abs(mod.Count) || 1)).fill(false),
            failed: Array(parseInt(Math.abs(mod.Count) || 1)).fill(false),
          },
        }));
      });
    }
    item.Attachments.push(attachment);
    item.HPUsed = (item.HPUsed || 0) + parseInt(attachment.HP);
    item.Price = parseInt(item.Price) + parseInt(attachment.Price);
    let modItem = editQualities(item, attachment.BaseMods)
    newCategory[newCategory.findIndex((i) => i.inventoryID === item.inventoryID)] = modItem;
    setPreviewItem(modItem)
    handleCharacterChange({
      [category]: newCategory,
      Credits: character.Credits - parseInt(attachment.Price),
    });
    handleCloseAttachments();
  };

  const handleRemoveAttachment = (item, attachment, attachI) => {
    let newCategory = [...character[category]];
    attachment.AddedMods.forEach((mod) => {
      setModStates((prevModStates) => {
        const { [`${item.inventoryID}-${attachment.Key}-${mod.Key}`]: _, ...rest } = prevModStates;
        return rest;
      });
    });
    item.Attachments.splice(attachI, 1);
    item.HPUsed = (item.HPUsed || 0) - parseInt(attachment.HP || 0);
    item.Price = parseInt(item.Price) - parseInt(attachment.Price);
    let modItem = editQualities(item, attachment.BaseMods, true)
    setPreviewItem(modItem)
    newCategory[newCategory.findIndex((i) => i.inventoryID === item.inventoryID)] = modItem;
    handleCharacterChange({
      [category]: newCategory,
    });
  };

  const handleRefundAttachment = (item, attachment, attachI) => {
    let newCategory = [...character[category]];

    if (attachment.AddedMods) {
      attachment.AddedMods.forEach((mod) => {
        setModStates((prevModStates) => {
          const { [`${item.inventoryID}-${attachment.Key}-${mod.Key}`]: _, ...rest } = prevModStates;
          return rest;
        });
      });
    }
    item.Attachments.splice(attachI, 1);
    item.HPUsed = (item.HPUsed || 0) - parseInt(attachment.HP || 0);
    item.Price = parseInt(item.Price) - parseInt(attachment.Price);
    let modItem = editQualities(item, attachment.BaseMods, true)
    setPreviewItem(modItem)
    newCategory[newCategory.findIndex((i) => i.inventoryID === item.inventoryID)] = modItem;
    handleCharacterChange({
      [category]: newCategory,
      Credits: character.Credits + parseInt(attachment.Price),
    });
  };

  const critSymbols = (count) => {
    let critPool = "[AD]".repeat(count);
    return critPool;
  };

  const WeaponDetails = (key) => {
    if (!key.item) { return null }
    let weapon = weapons.find((w) => w.Key === key.item)

    return (
      <Paper className="p-4">
        <Typography variant="h6" className="mb-2">{weapon.Name}</Typography>
        <Typography className="mb-1">Skill: {skills.find((s) => s.Key === weapon.SkillKey).skill}</Typography>
        <Typography className="mb-1">Damage: {weapon.Damage}</Typography>
        <div
          dangerouslySetInnerHTML={{
            __html: MarkedText.renderer(
              `Critical: ${critSymbols(weapon.Crit)}`,
            ),
          }}
        />
        <Typography className="mb-1">Range: {weapon.Range}</Typography>
        {weapon.Qualities && (
          <div
            dangerouslySetInnerHTML={{
              __html: MarkedText.renderer(
                `${qualitiesString(
                  weapon.Qualities,
                )}`
              ),
            }}
          />
        )}
      </Paper>
    );
  };

  if (!previewItem.Name) { return (<></>) }

  return (
    <Paper variant="outlined">
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Typography variant="h5" style={{ padding: "15px" }}>
            {previewItem.Name}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          {previewOwned && previewItem.Name && (tabValue === 0 || tabValue === 1) && (
            <Button onClick={() => handleOpenAttachments(previewItem)}>
              Attachments
            </Button>)}
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <List>
            {previewItem.Price > 0 && (
              <ListItem>{`Price: ${previewItem.Price} credits`}</ListItem>
            )}
            {previewItem.Rarity === 0 ?
              <ListItem>Rarity: 0</ListItem> :
              parseInt(previewItem.Rarity) > 0 ? <ListItem>Rarity: {previewItem.Rarity}</ListItem> :
                ''}
            {previewItem.Encumbrance === 0 ?
              <ListItem>Encumbrance: 0</ListItem> :
              previewItem.Encumbrance ? <ListItem>Encumbrance: {previewItem.Encumbrance}</ListItem> :
                ''}
            {previewItem.Damage && previewItem.DamageAdd && (
              <ListItem>
                {`Damage: ${previewItem.Damage + " + " + previewItem.DamageAdd
                  }`}
              </ListItem>
            )}
            {previewItem.Damage && !previewItem.DamageAdd && (
              <ListItem>{`Damage: ${previewItem.Damage}`}</ListItem>
            )}
            {!previewItem.Damage && (previewItem.DamageAdd || previewItem.DamageAdd === 0) && (
              <ListItem>
                {previewItem.DamageAdd
                  ? `Damage: Brawn + ${previewItem.DamageAdd}`
                  : `Damage: Brawn + 0`}
              </ListItem>
            )}
            {previewItem.Crit && (
              <div
                style={{ padding: "15px" }}
                dangerouslySetInnerHTML={{
                  __html: MarkedText.renderer(
                    `Critical: ${critSymbols(previewItem.Crit)}`,
                  ),
                }}
              />
            )}
            {previewItem.Qualities && (
              <ListItem>
                <div
                  dangerouslySetInnerHTML={{
                    __html: MarkedText.renderer(
                      `${qualitiesString(
                        previewItem.Qualities,
                      )}`
                    ),
                  }}
                />
              </ListItem>
            )}
            {previewItem.Range && (
              <ListItem>{`Range: ${previewItem.Range}`}</ListItem>
            )}
            {previewItem.SkillKey && (
              <ListItem>
                {`Skill: ${skills.find((s) => s.Key === previewItem.SkillKey).skill
                  }`}
              </ListItem>
            )}
            {previewItem.Soak && (
              <ListItem>{`Soak: ${previewItem.Soak}`}</ListItem>
            )}
            {previewItem.Defense && (
              <ListItem>{`Defense: ${previewItem.Defense}`}</ListItem>
            )}
            {previewItem.HP && (
              <ListItem>{`HP: ${previewItem.HP}`}</ListItem>
            )}
            {previewItem.Type && (
              <ListItem>{`Type: ${previewItem.Type}`}</ListItem>
            )}
            {previewItem.BaseMods && (
              <>
                {previewItem.BaseMods.MiscDesc && (<div
                  style={{ padding: "15px" }}
                  dangerouslySetInnerHTML={{
                    __html: MarkedText.renderer(
                      previewItem.BaseMods.MiscDesc,
                    ),
                  }}
                />)}
                {previewItem.BaseMods.Key && (
                  < div
                    style={{ padding: "15px" }}
                    dangerouslySetInnerHTML={{
                      __html: MarkedText.renderer(getModText(qualities, talents, skills, previewItem.BaseMods)),
                    }}
                  />
                )}
              </>
            )}
            {previewItem.BaseMods &&
              previewItem.BaseMods[0] &&
              previewItem.BaseMods.map((mod) => (
                <>
                  {mod.MiscDesc && (<div
                    style={{ padding: "15px" }}
                    dangerouslySetInnerHTML={{
                      __html: MarkedText.renderer(mod.MiscDesc),
                    }}
                  />)}
                  {mod.Key && (
                    <div
                      style={{ padding: "15px" }}
                      dangerouslySetInnerHTML={{
                        __html: MarkedText.renderer(getModText(qualities, talents, skills, mod)),
                      }}
                    />
                  )}
                </>
              ))}
            {previewItem.Attachments && (
              <Container>
                {previewItem.Attachments[0] && (
                  <Typography variant="h6">Attachments</Typography>
                )}
                {previewItem.Attachments.map((attachment, attachI) => (
                  <Paper key={attachment.Key + attachI} variant="outlined">
                    <Typography sx={{ mt: "5px" }}>
                      {attachment.Name}
                    </Typography>
                    <Typography sx={{ mt: "5px" }}>
                      HP: {attachment.HP}
                    </Typography>
                    {attachment.BaseMods && (
                      <Typography sx={{ mt: "5px" }}>
                        Base Modifiers:{" "}
                        {attachment.BaseMods.map((mod, index) => (
                          <div key={mod.Key + index}>
                            {mod.MiscDesc && (<div
                              style={{ padding: "5px" }}
                              dangerouslySetInnerHTML={{
                                __html: MarkedText.renderer(mod.MiscDesc),
                              }}
                            />)}
                            {mod.Key && (
                              <div
                                style={{ padding: "5px" }}
                                dangerouslySetInnerHTML={{
                                  __html: MarkedText.renderer(getModText(qualities, talents, skills, mod)),
                                }}
                              />
                            )}
                          </div>
                        ))}
                      </Typography>
                    )}
                    {attachment.AddedMods && (
                      <Typography sx={{ mt: "5px" }}>
                        Modification Options:{" "}
                        {attachment.AddedMods.map((mod, index) => {
                          const modKey = `${previewItem.inventoryID}-${attachment.Key}-${mod.Key}`;
                          const modState = modStates[modKey] || {
                            installed: Array(parseInt(Math.abs(mod.Count) || 1)).fill(false),
                            failed: Array(parseInt(Math.abs(mod.Count) || 1)).fill(false),
                          };
                          return (
                            <div key={mod.Key + index}>
                              <div
                                style={{ padding: "5px" }}
                                dangerouslySetInnerHTML={{
                                  __html: MarkedText.renderer(mod.MiscDesc),
                                }}
                              />
                              <div>
                                <div>
                                  {Array(parseInt(mod.Count ? Math.abs(mod.Count) : 1))
                                    .fill(0)
                                    .map((_, i) => (
                                      <div key={`${modKey}-${i}`}>
                                        <Grid container>
                                          <Grid item style={{ display: "flex", alignItems: "center" }}>
                                            <div
                                              style={{
                                                padding: "5px",
                                                margin: "auto",
                                              }}
                                              dangerouslySetInnerHTML={{
                                                __html: MarkedText.renderer(getModText(qualities, talents, skills, mod, true)),
                                              }}
                                            />
                                          </Grid>
                                          <Grid item>
                                            <FormControlLabel
                                              control={
                                                <Checkbox
                                                  checked={modState.installed[i]}
                                                  onChange={(event) => {
                                                    const isInstalled = event.target.checked;
                                                    let newModStates = {
                                                      ...modStates,
                                                      [modKey]: {
                                                        ...modState,
                                                        installed: modState.installed.map(
                                                          (installed, j) => (j === i ? isInstalled : installed)
                                                        ),
                                                        failed: modState.failed.map(
                                                          (failed, j) => (j === i ? false : failed)
                                                        ),
                                                      },
                                                    }
                                                    setModStates(newModStates);
                                                    let oneMod = { ...mod };
                                                    if (oneMod.Count) {
                                                      oneMod.Count < 0 ? oneMod.Count = -1 : oneMod.Count = 1;
                                                    }
                                                    let eq = editQualities(previewItem, [oneMod], isInstalled ? false : true);
                                                    eq.ModStates = newModStates
                                                    setPreviewItem(eq)
                                                    let newCategory = [...character[category]]
                                                    newCategory[newCategory.findIndex((i) => i.inventoryID === previewItem.inventoryID)] = eq;
                                                    handleCharacterChange({
                                                      [category]: newCategory,
                                                      Credits: character.Credits + (isInstalled ? -100 : 100),
                                                    });
                                                  }}
                                                  disabled={modState.failed[i]}
                                                />
                                              }
                                              label="Installed"
                                            />
                                          </Grid>
                                          <Grid item>
                                            <FormControlLabel
                                              control={
                                                <Checkbox
                                                  checked={modState.failed[i]}
                                                  onChange={(event) => {
                                                    const isFailed = event.target.checked;
                                                    let newModStates = {
                                                      ...modStates,
                                                      [modKey]: {
                                                        ...modState,
                                                        failed: modState.failed.map(
                                                          (failed, j) => (j === i ? isFailed : failed)
                                                        ),
                                                        installed: modState.installed.map(
                                                          (installed, j) => (j === i ? false : installed)
                                                        ),
                                                      },
                                                    }
                                                    setModStates(newModStates);
                                                    previewItem.ModStates = newModStates
                                                    let newCategory = [...character[category]]
                                                    newCategory[newCategory.findIndex((i) => i.inventoryID === previewItem.inventoryID)] = previewItem;
                                                    handleCharacterChange({
                                                      [category]: newCategory,
                                                      Credits: character.Credits + (isFailed ? -100 : 100),
                                                    });
                                                  }}
                                                  disabled={modState.installed[i]}
                                                />
                                              }
                                              label="Failed"
                                            />
                                          </Grid>
                                        </Grid>
                                      </div>
                                    ))}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </Typography>
                    )}
                    <Button
                      onClick={() =>
                        handleRemoveAttachment(
                          previewItem,
                          attachment,
                          attachI,
                        )
                      }
                    >
                      Remove
                    </Button>
                    <Button
                      onClick={() =>
                        handleRefundAttachment(
                          previewItem,
                          attachment,
                          attachI,
                        )
                      }
                    >
                      Refund
                    </Button>
                  </Paper>
                ))}
              </Container>
            )}
          </List>
        </Grid>
        <Grid item xs={12} sm={6}>
          {previewItem.imageUrl && <Card
            style={{
              maxHeight: "500px",
              maxWidth: "100%",
              minHeight: "20px",
              minWidth: "200px",
              display: "flex",
              padding: "8px",
            }}
          >
            <CardMedia
              component="img"
              image={previewItem.imageUrl}
              alt="Item Image"
              style={{ objectFit: "contain" }}
            />
          </Card>}
          <div
            style={{ padding: "15px" }}
            dangerouslySetInnerHTML={{
              __html: MarkedText.renderer(previewItem.Description),
            }}
          />
          {previewItem.Source && (<Typography>{formatSource(previewItem.Source)}</Typography>)}
        </Grid>
      </Grid>
      {(() => {
        let foundAdversary
        if (previewItem.Characteristics) {
          foundAdversary = previewItem;
        } else {
          foundAdversary = previewItem.Profile
        }
        return foundAdversary && (
          <ListItem>
            <div style={{ width: "100%", paddingRight: "15px", paddingLeft: "15px", paddingTop: "0px", textAlign: "center" }}>
              <Typography variant="h6">{foundAdversary.AdversaryType}</Typography>
              <Grid container sx={{ textAlign: "center" }}>
                <Grid item xs={4} md={2} lg={4} xl={2}>
                  <StatForm
                    title="Brawn"
                    value={parseInt(foundAdversary.Characteristics.BR) + (foundAdversary.SuperiorHardware?.filter((a) => a === "BR").length || 0)}
                    color="red"
                    sizes={["h6", "h5"]}
                  />
                </Grid>
                <Grid item xs={4} md={2} lg={4} xl={2}>
                  <StatForm
                    title="Agility"
                    value={parseInt(foundAdversary.Characteristics.AG) + (foundAdversary.SuperiorHardware?.filter((a) => a === "AG").length || 0)}
                    color="red"
                    sizes={["h6", "h5"]}
                  />
                </Grid>
                <Grid item xs={4} md={2} lg={4} xl={2}>
                  <StatForm
                    title="Intellect"
                    value={parseInt(foundAdversary.Characteristics.INT) + (foundAdversary.SuperiorHardware?.filter((a) => a === "INT").length || 0)}
                    color="red"
                    sizes={["h6", "h5"]}
                  />
                </Grid>
                <Grid item xs={4} md={2} lg={4} xl={2}>
                  <StatForm
                    title="Cunning"
                    value={parseInt(foundAdversary.Characteristics.CUN) + (foundAdversary.SuperiorHardware?.filter((a) => a === "CUN").length || 0)}
                    color="red"
                    sizes={["h6", "h5"]}
                  />
                </Grid>
                <Grid item xs={4} md={2} lg={4} xl={2}>
                  <StatForm
                    title="Willpower"
                    value={parseInt(foundAdversary.Characteristics.WIL) + (foundAdversary.SuperiorHardware?.filter((a) => a === "WIL").length || 0)}
                    color="red"
                    sizes={["h6", "h5"]}
                  />
                </Grid>
                <Grid item xs={4} md={2} lg={4} xl={2}>
                  <StatForm
                    title="Presence"
                    value={parseInt(foundAdversary.Characteristics.PR) + (foundAdversary.SuperiorHardware?.filter((a) => a === "PR").length || 0)}
                    color="red"
                    sizes={["h6", "h5"]}
                  />
                </Grid>
              </Grid>

              <Grid container sx={{ textAlign: "center", display: "flex", alignItems: "center", minHeight: "100%" }} >
                <Grid item xs={6} md={foundAdversary.Attributes.StrainThreshold ? 3 : 3}>
                  <StatForm
                    title="Wound Threshold"
                    value={foundAdversary.Attributes.WoundThreshold || "-"}
                    color="blue"
                    sizes={["h8", "h5"]}
                  />
                </Grid>
                {foundAdversary.Attributes.StrainThreshold && <Grid item xs={6} md={3}>
                  <StatForm
                    title="Strain Threshold"
                    value={foundAdversary.Attributes.StrainThreshold}
                    color="blue"
                    sizes={["h8", "h5"]}
                  />
                </Grid>}
                <Grid item xs={foundAdversary.Attributes.StrainThreshold ? 4 : 6} md={3}>
                  <StatForm
                    title="Soak"
                    value={foundAdversary.Attributes.Soak}
                    color="blue"
                    sizes={["h8", "h5"]}
                  />
                </Grid>
                <Grid item xs={foundAdversary.Attributes.StrainThreshold ? 8 : 12} md={foundAdversary.Attributes.StrainThreshold ? 3 : 6}>
                  <Typography variant="h8">Defense</Typography>
                  <Grid container sx={{ paddingRight: "7.5px", paddingLeft: "10px" }}>
                    <Grid item xs={6} sx={{ paddingRight: "2.5px" }}>
                      <StatForm
                        title="Ranged"
                        value={foundAdversary.Attributes.DefenseRanged || foundAdversary.Attributes.Defense ? foundAdversary.Attributes.Defense[1] : 0}
                        color="blue"
                        sizes={["h8", "h5"]}
                      />
                    </Grid>
                    <Grid item xs={6} sx={{ paddingLeft: "2.5px" }}>
                      <StatForm
                        title="Melee"
                        value={foundAdversary.Attributes.DefenseMelee || foundAdversary.Attributes.Defense ? foundAdversary.Attributes.Defense[0] : 0}
                        color="blue"
                        sizes={["h8", "h5"]}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <div style={{ textAlign: "left" }}>
                <Grid container>
                  <Grid item xs={12} sm={6}>
                    {foundAdversary.Skills && <div>
                      <Typography variant="h6" sx={{ paddintTop: "10px" }}>Skills:</Typography>
                      {(Array.isArray(foundAdversary.Skills) && foundAdversary.Skills[0] && foundAdversary.Skills[0].Key) && foundAdversary.Skills.map((skill, index) => (
                        <React.Fragment key={skill.Key}>
                          <Typography variant="h7">
                            {skills.find((s) => s.Key === skill.Key).skill}
                            {foundAdversary.AdversaryType !== "Minion" ? ` ${skill.Rank}` : ""}
                            {index < foundAdversary.Skills.length - 1 ? ", " : ""}
                          </Typography>
                        </React.Fragment>
                      ))}
                      {(Array.isArray(foundAdversary.Skills) && foundAdversary.Skills[0] && !foundAdversary.Skills[0].Key) && foundAdversary.Skills.map((skill, index) => (
                        <React.Fragment key={skill.Key}>
                          <Typography variant="h7">
                            {skill}
                            {(index < foundAdversary.Skills.length - 1) && ", "}
                          </Typography>
                        </React.Fragment>
                      ))}
                      {!Array.isArray(foundAdversary.Skills) && Object.entries(foundAdversary.Skills).map(([skillName, rank], index, arr) => (
                        <React.Fragment key={skillName}>
                          <Typography variant="h7">
                            {skillName}
                            {foundAdversary.AdversaryType !== "Minion" && rank ? ` ${rank}` : ""}
                            {index < arr.length - 1 ? ", " : ""}
                          </Typography>
                        </React.Fragment>
                      ))}
                    </div>}
                    {foundAdversary.Talents && foundAdversary.Talents[0] && foundAdversary.Talents[0].Key && <div>
                      <Typography variant="h6" sx={{ paddintTop: "10px" }}>Talents:</Typography>
                      {foundAdversary.Talents.map((talent) => (
                        <React.Fragment key={talent.Key}>
                          <Typography variant="h7">
                            {talents.find((t) => t.Key === talent.Key).Name} {parseInt(talent.Ranks)}
                          </Typography>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: MarkedText.renderer(talents.find((t) => t.Key === talent.Key).Description)
                            }}
                          />
                        </React.Fragment>
                      ))}
                    </div>}
                    {foundAdversary.Talents && (!foundAdversary.Talents[0] || !foundAdversary.Talents[0].Key) && <div>
                      <Typography variant="h6" sx={{ paddintTop: "10px" }}>Talents:</Typography>
                      {foundAdversary.Talents.map((talent, index) => (
                        <React.Fragment key={talent + index}>
                          <Typography variant="h7">
                            {talent}
                            {index < foundAdversary.Talents.length - 1 ? ", " : ""}
                          </Typography>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: MarkedText.renderer((talents.find((t) => t.Key === talent.Key) || talents.find((t) => talent.includes(t.Name))).Description)
                            }}
                          />
                        </React.Fragment>
                      ))}
                    </div>}

                    {(foundAdversary.Abilities) && (
                      <>
                        <Typography variant="h6" sx={{ paddintTop: "10px" }}>Abilities:</Typography>
                        <div>
                          {foundAdversary.Abilities?.map((mod, index) => (
                            (<>
                              {mod.Description && <div
                                key={index}
                                dangerouslySetInnerHTML={{
                                  __html: MarkedText.renderer(mod.Name + ": " + mod.Description)
                                }}
                              />}
                              {!mod.Description && <div
                                key={index}
                                dangerouslySetInnerHTML={{
                                  __html: MarkedText.renderer(mod)
                                }}
                              />}
                            </>
                            )
                          ))}
                        </div>
                      </>
                    )}

                    {foundAdversary.Cyber && <>
                      <Typography variant="h6" sx={{ paddintTop: "10px" }}>{foundAdversary.Cyber.Name}</Typography>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: MarkedText.renderer(foundAdversary.Cyber.Description)
                        }}
                      />
                    </>}
                    {foundAdversary.BaseMods && Array.isArray(foundAdversary.BaseMods) && (
                      <div>
                        {foundAdversary.BaseMods.map((mod, index) => (
                          (
                            mod.MiscDesc && <div
                              key={index}
                              dangerouslySetInnerHTML={{
                                __html: MarkedText.renderer(mod.MiscDesc)
                              }}
                            />
                          )
                        ))}
                      </div>
                    )}
                    {foundAdversary.BaseMods && foundAdversary.BaseMods.MiscDesc &&
                      <div
                        dangerouslySetInnerHTML={{
                          __html: MarkedText.renderer(foundAdversary.BaseMods.MiscDesc)
                        }} />}

                    {(foundAdversary.Silhouette === 0 || foundAdversary.Silhouette === 2) && <Typography>Silhouette: {foundAdversary.Silhouette}</Typography>}
                  </Grid>
                  {(foundAdversary.Gear || foundAdversary.Weapons) &&
                    <Grid item xs={12} sm={6} sx={{ paddingLeft: "10px" }}>
                      {foundAdversary.Weapons && foundAdversary.Weapons.map((item, index) => <div key={item + index}>
                        <div style={{ height: "5px" }} />
                        {item.Name && <Paper>
                          <Typography variant="h6">{item.Name}</Typography>
                          <Typography>Skill: {item.Skill}</Typography>
                          <Typography>Damage: {item.Damage}</Typography>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: MarkedText.renderer(
                                `Critical: ${critSymbols(item.Crit)}`,
                              ),
                            }}
                          />
                          <Typography>Range: {item.Range}</Typography>
                          {item.Qualities && (
                            <Typography> Qualities: {' '}
                              {item.Qualities.map((qual, index) => (
                                <React.Fragment key={index}>
                                  {qual}
                                  {index < item.Qualities.length - 1 ? ', ' : ''}
                                </React.Fragment>
                              ))}
                            </Typography>
                          )}
                        </Paper>}
                        {!item.Name && <WeaponDetails item={item} />}
                      </div>)}
                      {foundAdversary.Gear &&
                        <div
                          dangerouslySetInnerHTML={{
                            __html: MarkedText.renderer('Equipment: ' + foundAdversary.Gear)
                          }}
                        />
                      }
                    </Grid>}
                </Grid>
              </div>
            </div>
          </ListItem>
        );
      })()}
      {
        openAttachments &&
        editedItem &&
        editedItem.Key === previewItem.Key && (
          <Modal open={openAttachments} onClose={handleCloseAttachments}>
            <Paper
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                padding: "25px",
                width: "100%",
              }}
            >
              <Typography variant="h6">
                {editedItem ? editedItem.Name : ""} Attachments
              </Typography>
              <Typography>HP Used: {editedItem.Attachments?.reduce((sum, attachment) => sum + (parseInt(attachment.HP) || 0), 0) || 0} / {editedItem.HP}</Typography>
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <TableContainer
                    component={Paper}
                    style={{
                      maxHeight: "calc(100vh - 30vh)",
                      minHeight: "20vh",
                      overflowY: "auto",
                    }}
                  >
                    <Table stickyHeader size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Name</TableCell>
                          <TableCell align="center">HP</TableCell>
                          <TableCell align="right">Price</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {attachments
                          .filter((a) => {
                            const typeMatch = a.Type === (category === "Weapons" ? "Weapon" : category);

                            const categoryMatch = !a.CategoryLimit || (
                              Array.isArray(a.CategoryLimit) &&
                              (Array.isArray(editedItem.Categories)
                                ? editedItem.Categories.some(cat => a.CategoryLimit.includes(cat))
                                : a.CategoryLimit.includes(editedItem.Categories))
                            );

                            const itemMatch = !a.ItemLimit || a.ItemLimit === editedItem.Key;

                            const inItem = editedItem.Attachments?.find((at) => at.Key === a.Key)

                            const HPvalid = (a.HP || 0) <= (editedItem.HP - (editedItem.Attachments?.reduce((sum, attachment) => sum + (parseInt(attachment.HP) || 0), 0) || 0))

                            const juryValid = !(a.Key === "JURYRIGGEDA") && !(a.Key === "JURYRIGGEDW")

                            return typeMatch && categoryMatch && itemMatch && HPvalid && juryValid && !inItem;
                          }).sort((a, b) => {
                            return a.Name.localeCompare(b.Name);
                          })
                          .map((attachment, index) => (
                            <TableRow
                              key={attachment.Key + index}
                              hover
                              sx={{ cursor: "pointer" }}
                              onClick={() =>
                                setSelectedAttachment({ ...attachment })
                              }
                            >
                              <TableCell>{attachment.Name}</TableCell>
                              <TableCell align="center">
                                {attachment.HP || 0}
                              </TableCell>
                              <TableCell align="right">
                                {attachment.Price}
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Grid container>
                    <Grid item xs={12} sm={6}>
                      <div
                        style={{ padding: "15px" }}
                        dangerouslySetInnerHTML={{
                          __html: MarkedText.renderer(
                            selectedAttachment.Description,
                          ),
                        }}
                      />
                      {selectedAttachment.Source && (
                        <Typography>
                          {formatSource(selectedAttachment.Source)}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {selectedAttachment && (
                        <>
                          <Typography variant="h6">
                            {selectedAttachment.Name}
                          </Typography>
                          <Typography sx={{ mt: "40px" }}>
                            Price: {selectedAttachment.Price}
                          </Typography>
                          <Typography sx={{ mt: "5px" }}>
                            Rarity: {selectedAttachment.Rarity}
                          </Typography>
                          <Typography sx={{ mt: "5px" }}>
                            HP: {selectedAttachment.HP}
                          </Typography>
                          {selectedAttachment.BaseMods && (
                            <Typography sx={{ mt: "5px" }}>
                              Base Modifiers:{" "}
                              {selectedAttachment.BaseMods.map(
                                (mod, index) => (
                                  <div key={selectedAttachment.Key + index}>
                                    <div
                                      style={{ padding: "5px" }}
                                      dangerouslySetInnerHTML={{
                                        __html: MarkedText.renderer(
                                          mod.MiscDesc,
                                        ),
                                      }}
                                    />
                                    {mod.Key && (
                                      <div
                                        style={{ padding: "5px" }}
                                        dangerouslySetInnerHTML={{
                                          __html: MarkedText.renderer(
                                            qualities.find(
                                              (q) => q.Key === mod.Key,
                                            )
                                              ? (mod.Count || "") +
                                              " " +
                                              qualities.find(
                                                (q) => q.Key === mod.Key,
                                              ).ModDesc +
                                              (mod.Count < 0
                                                ? " -1"
                                                : "") +
                                              (mod.Count > 1 ||
                                                mod.Count < -1
                                                ? " mods"
                                                : " mod")
                                              : talents.find(
                                                (t) => t.Key === mod.Key,
                                              )
                                                ? "Innate Talent: " +
                                                talents.find(
                                                  (t) => t.Key === mod.Key,
                                                ).Name
                                                : skills.find(
                                                  (s) =>
                                                    s.Key === mod.Key,
                                                )
                                                  ? mod.Count +
                                                  " Skill: " +
                                                  skills.find(
                                                    (s) =>
                                                      s.Key === mod.Key,
                                                  ).skill +
                                                  (mod.Count > 1
                                                    ? " mods"
                                                    : " mod")
                                                  : "",
                                          ),
                                        }}
                                      />
                                    )}
                                  </div>
                                ),
                              )}
                            </Typography>
                          )}
                          {selectedAttachment.AddedMods && (
                            <Typography sx={{ mt: "5px" }}>
                              Modification Options:{" "}
                              {selectedAttachment.AddedMods.map(
                                (mod, index) => (
                                  <div key={(mod.Key || mod.MiscDesc) + index}>
                                    <div
                                      style={{ padding: "5px" }}
                                      dangerouslySetInnerHTML={{
                                        __html: MarkedText.renderer(
                                          mod.MiscDesc,
                                        ),
                                      }}
                                    />
                                    {mod.Key && (
                                      <div
                                        style={{ padding: "5px" }}
                                        dangerouslySetInnerHTML={{
                                          __html: MarkedText.renderer(
                                            qualities.find(
                                              (q) => q.Key === mod.Key,
                                            )
                                              ? mod.Count +
                                              " " +
                                              qualities.find(
                                                (q) => q.Key === mod.Key,
                                              ).ModDesc +
                                              (mod.Count > 1
                                                ? " mods"
                                                : " mod")
                                              : talents.find(
                                                (t) => t.Key === mod.Key,
                                              )
                                                ? "Innate Talent: " +
                                                talents.find(
                                                  (t) => t.Key === mod.Key,
                                                ).Name
                                                : skills.find(
                                                  (s) =>
                                                    s.Key === mod.Key,
                                                )
                                                  ? mod.Count +
                                                  " Skill: " +
                                                  skills.find(
                                                    (s) =>
                                                      s.Key === mod.Key,
                                                  ).skill +
                                                  (mod.Count > 1
                                                    ? " mods"
                                                    : " mod")
                                                  : "",
                                          ),
                                        }}
                                      />
                                    )}
                                  </div>
                                ),
                              )}
                            </Typography>
                          )}
                          <Button
                            onClick={() =>
                              handleSaveAttachments(
                                editedItem,
                                selectedAttachment,
                              )
                            }
                          >
                            Buy
                          </Button>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Modal>
        )
      }
    </Paper >
  );
}

function App({ character, handleCharacterChange, appFiles }) {
  const [tabValue, setTabValue] = useState(0);
  const [ownedWeapons, setOwnedWeapons] = useState([...character.Weapons]);
  const [ownedArmor, setOwnedArmor] = useState([...character.Armor]);
  const [ownedGear, setOwnedGear] = useState([...character.Gear]);
  const [previewItem, setPreviewItem] = useState({});
  const [previewOwned, setPreviewOwned] = useState(false);
  const [sortBy, setSortBy] = useState({
    field: null,
    direction: "asc",
  });
  const [craftingModalOpen, setCraftingModalOpen] = useState(false)
  const [droidCraftingModalOpen, setDroidCraftingModalOpen] = useState(false)
  const [editChange, setEditChange] = useState(false)

  useEffect(() => {
    if (editChange) { setEditChange(false) } else {
      let newOwnedItem = {}
      if (!previewOwned) {
        if (tabValue === 0 && ownedWeapons[0]) {
          newOwnedItem =
            ownedWeapons.slice(-1)[0]
        }
        if (tabValue === 1 && ownedArmor[0]) {
          newOwnedItem = ownedArmor.slice(-1)[0]
        }

        if (tabValue === 2 && ownedGear[0]) {
          newOwnedItem = ownedGear.slice(-1)[0]
        }
      }
      setPreviewItem(newOwnedItem);
      setPreviewOwned(!previewOwned)
    }
  }, [ownedWeapons, ownedArmor, ownedGear]);

  useEffect(() => {
    setEditChange(true)
    setOwnedWeapons([...character.Weapons]);
  }, [character.Weapons]);
  useEffect(() => {
    setEditChange(true)
    setOwnedArmor([...character.Armor]);
  }, [character.Armor]);
  useEffect(() => {
    setEditChange(true)
    setOwnedGear([...character.Gear]);
  }, [character.Gear]);

  useEffect(() => {
    if (appFiles.gear) { gear = JSON.parse(appFiles.gear) }
    if (appFiles.armor) { armor = JSON.parse(appFiles.armor) }
    if (appFiles.weapons) { weapons = JSON.parse(appFiles.weapons) }
    if (appFiles.skills) { skills = JSON.parse(appFiles.skills) }
    if (appFiles.qualities) { qualities = JSON.parse(appFiles.qualities) }
    if (appFiles.attachments) { attachments = JSON.parse(appFiles.attachments) }
    if (appFiles.talents) { talents = JSON.parse(appFiles.talents) }
  }, [appFiles.gear, appFiles.armor, appFiles.weapons, appFiles.skills, appFiles.qualities, appFiles.attachments, appFiles.talents]);

  const equipmentData = [
    {
      category: "Weapons",
      items: weapons.filter((w) => w.Type !== "Vehicle"),
    },
    {
      category: "Armor",
      items: armor,
    },
    {
      category: "Gear",
      items: gear,
    },
  ];

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
    setSortBy({ field: null, direction: "asc" });
  };

  const handleBuyItem = (item, tab) => {
    const UID = `${item.Key}_${Date.now()}`;
    item.inventoryID = UID;

    if (!item.Price) {
      item.Price = 0;
    }
    if (tab === 0) {
      let newWeapons = [...ownedWeapons];
      newWeapons.push(item);
      setOwnedWeapons(newWeapons);
      handleCharacterChange({
        Weapons: newWeapons,
        Credits: character.Credits - item.Price,
      });
    }
    if (tab === 1) {
      let newArmors = [...ownedArmor];
      newArmors.push(item);
      setOwnedArmor(newArmors);
      handleCharacterChange({
        Armor: newArmors,
        Credits: character.Credits - item.Price,
      });
    }
    if (tab === 2) {
      let newGears = [...ownedGear];
      let oldGear = newGears.find((g) => g.Key === item.Key)
      if (oldGear) {
        oldGear.Quantity = oldGear.Quantity ? oldGear.Quantity + (item.Quantity || 1) : 2
      }
      else {
        newGears.push(item);
      }
      setOwnedGear(newGears);
      handleCharacterChange({
        Gear: newGears,
        Credits: character.Credits - (item.Price * item.Quantity),
      });
    }
  };

  const handleSellItem = (item, tab) => {
    if (tab === 0) {
      let newWeapons = [...ownedWeapons];
      newWeapons.splice(
        newWeapons.findIndex((el) => el.inventoryID === item.inventoryID),
        1,
      );
      setOwnedWeapons(newWeapons);
      handleCharacterChange({
        Weapons: newWeapons,
        Credits: character.Credits + parseInt(item.Price),
      });
    }
    if (tab === 1) {
      let newArmors = [...ownedArmor];
      newArmors.splice(
        newArmors.findIndex((el) => el.inventoryID === item.inventoryID),
        1,
      );
      setOwnedArmor(newArmors);
      handleCharacterChange({
        Armor: newArmors,
        Credits: character.Credits + parseInt(item.Price),
      });
    }
    if (tab === 2) {
      let newGears = [...ownedGear];
      let oldItem = ownedGear.find((el) => el.inventoryID === item.inventoryID)
      if (item.Quantity && item.Quantity < (oldItem.Quantity || 1)) {
        newGears.find((el) => el.inventoryID === item.inventoryID).Quantity -= item.Quantity
      }
      else {
        newGears.splice(
          newGears.findIndex((el) => el.inventoryID === item.inventoryID),
          1,
        );
      }
      setOwnedGear(newGears);
      handleCharacterChange({
        Gear: newGears,
        Credits: character.Credits + (parseInt(item.Price) * (item.Quantity || 1)),
      });
    }
  };

  const ownedEquipmentData = () => {
    return [
      {
        category: "Weapons",
        items: ownedWeapons.filter(w => w.Key !== "UNARMED"),
      },
      {
        category: "Armor",
        items: ownedArmor,
      },
      {
        category: "Gear",
        items: ownedGear,
      },
    ];
  };

  return (
    <>
      <Grid container sx={{ height: '50px' }}>
        <Grid item xs={7} sm={6} sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-start', height: '100%' }}>
          <Tabs value={tabValue} onChange={handleChangeTab}>
            {equipmentData.map((category, index) => (
              <Tab label={category.category} key={index} />
            ))}
          </Tabs>
        </Grid>
        <Grid item xs={5} sm={6} sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-start', height: '100%' }}>
          <Button onClick={() => setCraftingModalOpen(true)}>Craft Item</Button>
          <Button onClick={() => setDroidCraftingModalOpen(true)}>Craft Droid</Button>
        </Grid>
      </Grid>
      <Grid container columnSpacing={1}>
        <Grid item xs={12} lg={6}>
          <EquipmentTable
            equipmentData={equipmentData}
            tabValue={tabValue}
            handleBuyItem={handleBuyItem}
            setPreviewItem={setPreviewItem}
            setPreviewOwned={setPreviewOwned}
            owned={false}
            sortBy={sortBy}
            setSortBy={setSortBy}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <EquipmentTable
            equipmentData={ownedEquipmentData()}
            tabValue={tabValue}
            handleBuyItem={handleBuyItem}
            handleSellItem={handleSellItem}
            setPreviewItem={setPreviewItem}
            setPreviewOwned={setPreviewOwned}
            owned={true}
            sortBy={sortBy}
            setSortBy={setSortBy}
          />
          <Preview character={character} handleCharacterChange={handleCharacterChange} previewOwned={previewOwned} previewItem={previewItem} setPreviewItem={setPreviewItem} tabValue={tabValue} category={equipmentData[tabValue].category} />
        </Grid>
      </Grid>
      <CraftingModal
        open={craftingModalOpen}
        handleClose={() => setCraftingModalOpen(false)}
        craftingTemplates={craftingTemplates}
        craftingResultTables={craftingResultTables}
        character={character}
        handleCharacterChange={handleCharacterChange}
        setOwnedArmor={setOwnedArmor}
        setOwnedWeapons={setOwnedWeapons}
        setOwnedGear={setOwnedGear}
        skills={skills}
        attachments={attachments}
        qualities={qualities}
        talents={talents}
      />
      <DroidCraftingModal
        open={droidCraftingModalOpen}
        handleClose={() => setDroidCraftingModalOpen(false)}
        craftingTemplates={craftingTemplates}
        craftingResultTables={craftingResultTables}
        character={character}
        handleCharacterChange={handleCharacterChange}
        setOwnedGear={setOwnedGear}
        talents={talents}
        skills={skills}
        gear={gear}
      />
    </>
  );
}

export default App;
